var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.StyledDialog,{attrs:{"visible":_setup.open,"content-props":{
    class: 'freetier-modal-card text-center rounded-lg px-8 py-4',
    elevation: 0,
  },"max-width":"600"},on:{"update:visible":_setup.handleChange}},[_c('h3',{class:{
      'pb-4': _setup.currentTier === 'pro',
    }},[_vm._v("\n    "+_vm._s(_setup.I18nFn("Your current"))+"\n    "),_c('span',[_vm._v(_vm._s(_setup.I18nFn(`${_setup.currentTier} plan`)))]),_vm._v("\n    "+_vm._s(_setup.I18nFn("is limited to"))+"\n    "),_c('strong',[_vm._v(_vm._s(_setup.modelLimit))]),_vm._v("\n    "+_vm._s(_setup.I18nFn("Valuation Models"))+"\n  ")]),_vm._v(" "),(_setup.currentTier !== 'pro')?_c('p',{staticClass:"px-10"},[_vm._v("\n    "+_vm._s(_setup.I18nFn(_setup.upgradeMsg))+"\n  ")]):_vm._e(),_vm._v(" "),(_setup.currentTier !== 'pro')?_c('v-btn',{class:{
      'white--text': !_setup.darkActive,
      'grey--text': _setup.darkActive,
      'text--darken-3': _setup.darkActive,
    },attrs:{"elevation":"0","to":`/account/subs?ref=${_setup.refCode}`,"color":_setup.darkActive ? 'primaryAction' : 'toolbarText'}},[_vm._v("\n    "+_vm._s(_setup.I18nFn(`Upgrade to ${_setup.tierToUpgradeTo}`))+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }