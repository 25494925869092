import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.starts-with.js";
import { nextTick } from "vue";
import { useEventListener } from "@vueuse/core";
import PeHeader from "./PeHeader.vue";
import PeFilter from "./PeFilter.vue";
import PeForecastedSummary from "./PeForecastedSummary.vue";
import PeOperatingData from "./PeOperatingData.vue";
import PeDeltaEstimates from "./PeDeltaEstimates.vue";
import PeSummary from "./PeSummary.vue";
import SectionToggle from "~/components/SectionToggle.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import usePeValuationModel from "~/functions/usePeValuationModel";
import OverwriteActiveModelDialog from "~/components/valuationModels/OverwriteActiveModelDialog.vue";
import ConfirmationDialog from "~/components/valuationModels/ConfirmationDialog.vue";
import SaveChangesDialog from "~/components/valuationModels/SaveChangesDialog.vue";
import TierWarningDialog from "~/components/valuationModels/TierWarningDialog.vue";
import StyledButton from "~/components/StyledButton.vue";
export default {
  __name: 'ValuationModel',
  props: {
    templates: {
      type: Object,
      "default": function _default() {}
    },
    initialSave: {
      type: Boolean,
      "default": false
    },
    previewMode: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _usePeValuationModel = usePeValuationModel(props),
      companyWarningMessage = _usePeValuationModel.companyWarningMessage,
      filters = _usePeValuationModel.filters,
      isFinancialDataSet = _usePeValuationModel.isFinancialDataSet,
      isModelDisabled = _usePeValuationModel.isModelDisabled,
      updateSingleInput = _usePeValuationModel.updateSingleInput,
      resetCount = _usePeValuationModel.resetCount,
      isValidLastActuals = _usePeValuationModel.isValidLastActuals,
      recommendedDataset = _usePeValuationModel.recommendedDataset,
      currentDataset = _usePeValuationModel.currentDataset,
      actualDataset = _usePeValuationModel.actualDataset,
      headerProps = _usePeValuationModel.headerProps,
      operatingDataProps = _usePeValuationModel.operatingDataProps,
      forecastSummaryProps = _usePeValuationModel.forecastSummaryProps,
      overallSummaryProps = _usePeValuationModel.overallSummaryProps,
      deltaEstimatesProps = _usePeValuationModel.deltaEstimatesProps,
      filterProps = _usePeValuationModel.filterProps;
    var $route = useRoute();
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      dev = _useBaseUtils.dev,
      customGroup = _useBaseUtils.customGroup,
      refCode = _useBaseUtils.refCode;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store,
      $vuetify = _useNuxtApp.$vuetify;
    var operatingDataRef = ref(null);
    var forecastedSummaryRef = ref(null);
    var deltaEstimatesRef = ref(null);
    var tableWidth = ref(0);
    var isDenseColumns = ref(true); // i just need to keep this as a key because the
    // highchart component bugs when i don't
    var updatesAmount = ref(0);
    var autosave = computed(function () {
      return $store.state.valuationModel.autosave;
    });
    useEventListener(document, "visibilitychange", function () {
      if (document.visibilityState === "hidden" && !autosave.value) {
        $store.dispatch("valuationModel/setUserLeaving", true);
      }
    });
    var handleSelectChange = function handleSelectChange(newValue) {
      var newDataset = newValue.toLowerCase();
      var recommendedStr = "recommended";
      var dataset = newDataset.startsWith(recommendedStr) ? recommendedStr : newDataset;
      $store.dispatch("valuationModel/updateDataset", dataset);
      nextTick(function () {
        return setLargestTableSize();
      });
    };
    var setLargestTableSize = function setLargestTableSize() {
      tableWidth.value = "auto";
      nextTick(function () {
        var _operatingDataRef$val, _forecastedSummaryRef, _deltaEstimatesRef$va;
        var largestTableWidthFromRef = Math.max(((_operatingDataRef$val = operatingDataRef.value) === null || _operatingDataRef$val === void 0 || (_operatingDataRef$val = _operatingDataRef$val.$el) === null || _operatingDataRef$val === void 0 ? void 0 : _operatingDataRef$val.clientWidth) || 0, ((_forecastedSummaryRef = forecastedSummaryRef.value) === null || _forecastedSummaryRef === void 0 || (_forecastedSummaryRef = _forecastedSummaryRef.$el) === null || _forecastedSummaryRef === void 0 || (_forecastedSummaryRef = _forecastedSummaryRef.querySelector("table")) === null || _forecastedSummaryRef === void 0 ? void 0 : _forecastedSummaryRef.clientWidth) || 0, ((_deltaEstimatesRef$va = deltaEstimatesRef.value) === null || _deltaEstimatesRef$va === void 0 || (_deltaEstimatesRef$va = _deltaEstimatesRef$va.$el) === null || _deltaEstimatesRef$va === void 0 || (_deltaEstimatesRef$va = _deltaEstimatesRef$va.querySelector("table")) === null || _deltaEstimatesRef$va === void 0 ? void 0 : _deltaEstimatesRef$va.clientWidth) || 0);
        tableWidth.value = largestTableWidthFromRef;
      });
    };
    watch([operatingDataRef, forecastedSummaryRef, deltaEstimatesRef, isDenseColumns], function (newValues) {
      var isDenseColumNewState = newValues[3];
      if (isDenseColumNewState === true) {
        tableWidth.value = "inherit";
      } else {
        setLargestTableSize();
      }
    }, {
      immediate: true
    });
    var handleValueChange = function handleValueChange(value, calendarYear, id) {
      var currentAssumptionCaseIdx = filters.value.assumptionCase;
      updatesAmount.value += 1; // we need to prevent divisions by zero
      var finalValue = value === 0 ? 0.01 : value;
      var updatedObj = updateSingleInput(id, finalValue, calendarYear, currentAssumptionCaseIdx);
      $store.dispatch("valuationModel/updateUserInputs", {
        updatedObj: updatedObj,
        userTier: customGroup.value,
        refCode: refCode.value
      });
    };
    var companyModels = computed(function () {
      var cid = $route.query.cid;
      return $store.state.valuationModel.allModels[cid];
    });
    var activeModelId = computed(function () {
      var _companyModels$value;
      return ((_companyModels$value = companyModels.value) === null || _companyModels$value === void 0 || (_companyModels$value = _companyModels$value.find(function (model) {
        return model.isActive;
      })) === null || _companyModels$value === void 0 ? void 0 : _companyModels$value.modelId) || false;
    });
    var shouldRemove = computed(function () {
      var modelId = $route.query.modelId;
      return modelId && activeModelId.value && modelId === activeModelId.value;
    });
    var autoSave = computed(function () {
      return $store.state.valuationModel.autosave;
    });
    var isUserLeaving = computed(function () {
      return $store.state.valuationModel.isUserLeaving;
    });
    var containerStyle = computed(function () {
      if (isDenseColumns.value) {
        return {
          "--table-width": "auto"
        };
      }
      return {
        "--td-width": "120px",
        "--table-width": typeof tableWidth.value === "string" ? tableWidth.value : "".concat(tableWidth.value, "px")
      };
    });
    var errorMsg = computed(function () {
      return $store.state.valuationModel.error.message;
    });
    watch(errorMsg, function (newErrorMsg) {
      if (newErrorMsg) {
        nextTick(function () {
          return $vuetify.goTo("#error-model-message");
        });
      }
    });
    var isOutdatedData = computed(function () {
      return $store.state.valuationModel.isOutdatedData;
    });
    var capitalizeFirstLetter = function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    var handleCloseWarning = function handleCloseWarning() {
      $store.dispatch("valuationModel/changeDialogState", {
        dialogId: "tierWarning",
        value: false
      });
    };
    var isOutdated = computed(function () {
      return $store.state.valuationModel.isOutdatedData;
    }); // For now this is random until we finish the
    // logic for handling when the user has a different
    // tier than the shared model (FE-532)
    var isLockedModel = computed(function () {
      return props.previewMode && Math.random() < 0.5;
    });
    onMounted(function () {
      // we only update if we're not using outdated data
      if (props.initialSave && !isOutdated.value) {
        $store.dispatch("valuationModel/saveOrEditModel", {
          userTier: customGroup.value,
          refCode: refCode.value
        });
      }
    });
    return {
      __sfc: true,
      props: props,
      companyWarningMessage: companyWarningMessage,
      filters: filters,
      isFinancialDataSet: isFinancialDataSet,
      isModelDisabled: isModelDisabled,
      updateSingleInput: updateSingleInput,
      resetCount: resetCount,
      isValidLastActuals: isValidLastActuals,
      recommendedDataset: recommendedDataset,
      currentDataset: currentDataset,
      actualDataset: actualDataset,
      headerProps: headerProps,
      operatingDataProps: operatingDataProps,
      forecastSummaryProps: forecastSummaryProps,
      overallSummaryProps: overallSummaryProps,
      deltaEstimatesProps: deltaEstimatesProps,
      filterProps: filterProps,
      $route: $route,
      darkActive: darkActive,
      dev: dev,
      customGroup: customGroup,
      refCode: refCode,
      $store: $store,
      $vuetify: $vuetify,
      operatingDataRef: operatingDataRef,
      forecastedSummaryRef: forecastedSummaryRef,
      deltaEstimatesRef: deltaEstimatesRef,
      tableWidth: tableWidth,
      isDenseColumns: isDenseColumns,
      updatesAmount: updatesAmount,
      autosave: autosave,
      handleSelectChange: handleSelectChange,
      setLargestTableSize: setLargestTableSize,
      handleValueChange: handleValueChange,
      companyModels: companyModels,
      activeModelId: activeModelId,
      shouldRemove: shouldRemove,
      autoSave: autoSave,
      isUserLeaving: isUserLeaving,
      containerStyle: containerStyle,
      errorMsg: errorMsg,
      isOutdatedData: isOutdatedData,
      capitalizeFirstLetter: capitalizeFirstLetter,
      handleCloseWarning: handleCloseWarning,
      isOutdated: isOutdated,
      isLockedModel: isLockedModel,
      PeHeader: PeHeader,
      PeFilter: PeFilter,
      PeForecastedSummary: PeForecastedSummary,
      PeOperatingData: PeOperatingData,
      PeDeltaEstimates: PeDeltaEstimates,
      PeSummary: PeSummary,
      SectionToggle: SectionToggle,
      OverwriteActiveModelDialog: OverwriteActiveModelDialog,
      ConfirmationDialog: ConfirmationDialog,
      SaveChangesDialog: SaveChangesDialog,
      TierWarningDialog: TierWarningDialog,
      StyledButton: StyledButton
    };
  }
};