import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import PeModelLoader from "~/components/valuationModels/SingleModelLoader.vue";
import PeHead from "~/components/valuationModels/Pe/PeHead.vue";
import { templates } from "~/utils/valuationTransform/peValuationTemplates";
export default {
  __name: 'ValuationModelWrapper',
  props: {
    modelId: {
      type: String,
      required: false,
      "default": ""
    },
    preview: {
      type: Boolean,
      required: false,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useNuxtApp = useNuxtApp(),
      $featureToggle = _useNuxtApp.$featureToggle,
      $store = _useNuxtApp.$store;
    var _useBaseUtils = useBaseUtils(),
      customGroup = _useBaseUtils.customGroup,
      dev = _useBaseUtils.dev,
      refCode = _useBaseUtils.refCode;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
      parseUtcDateTime = _useDateTimeFormatter.parseUtcDateTime;
    var _useCiqTableOptions = useCiqTableOptions({
        tableType: "estimates",
        storeModule: "ciq"
      }),
      currentIso = _useCiqTableOptions.currentIso;
    var $route = useRoute();
    var fetchExistingModel = function fetchExistingModel() {
      var actionName = props.preview ? "retrievePreviewModel" : "retrieveModel";
      var action = "valuationModel/".concat(actionName);
      $store.dispatch(action, {
        uuid: props.modelId,
        userTier: customGroup.value,
        isDev: dev.value
      });
    };
    var tid = computed(function () {
      return $store.state.valuationModel.metadata.tradingitemid;
    });
    var cid = computed(function () {
      return $store.state.valuationModel.metadata.companyid;
    });
    var queryTitle = $route.query.title;
    var initialSaveQueryParam = $route.query.initialSave === "true";
    var showValuationModel = computed(function () {
      var _$featureToggle$toggl;
      return (_$featureToggle$toggl = $featureToggle.toggles) === null || _$featureToggle$toggl === void 0 ? void 0 : _$featureToggle$toggl["pe-valuation-model"];
    });
    var key = computed(function () {
      return props.modelId || "".concat(tid.value, "_").concat(cid.value);
    });
    var loadingStore = computed(function () {
      return $store.state.ciq.fetchingEstimates || $store.state.ciq.fetchingValuation || $store.state.ciq.fetchingFinancials;
    });
    var loadingVm = computed(function () {
      var _$store$state$valuati;
      return ((_$store$state$valuati = $store.state.valuationModel) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.loading) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati.initialLoad) || $store.state.ciq.fetchingValuation;
    });
    var loading = computed(function () {
      if (props.preview) {
        var _$store$state$valuati2;
        return (_$store$state$valuati2 = $store.state.valuationModel) === null || _$store$state$valuati2 === void 0 || (_$store$state$valuati2 = _$store$state$valuati2.loading) === null || _$store$state$valuati2 === void 0 ? void 0 : _$store$state$valuati2.initialLoad;
      }
      return loadingStore.value || loadingVm.value;
    });
    var hasStoreError = computed(function () {
      var keys = ["estimatesError", "valuationError", "financialsError"];
      return keys.some(function (key) {
        return $store.state.ciq[key];
      });
    });
    var isOutdated = computed(function () {
      var _$store$state$valuati3;
      return (_$store$state$valuati3 = $store.state.valuationModel) === null || _$store$state$valuati3 === void 0 ? void 0 : _$store$state$valuati3.isOutdated;
    });
    var userHasUpgradedOrDowngraded = computed(function () {
      var _$store$state$valuati4;
      var userTier = customGroup.value;
      var modelTier = (_$store$state$valuati4 = $store.state.valuationModel) === null || _$store$state$valuati4 === void 0 || (_$store$state$valuati4 = _$store$state$valuati4.metadata) === null || _$store$state$valuati4 === void 0 ? void 0 : _$store$state$valuati4.userTier;
      if (!modelTier || !userTier) {
        return false;
      }
      return modelTier !== userTier;
    });
    var initialSave = computed(function () {
      return userHasUpgradedOrDowngraded.value || initialSaveQueryParam;
    });
    onMounted(function () {
      if (props.preview) {
        fetchExistingModel(true);
      }
    });
    watch(loadingStore, function (loadingState) {
      var hasLoaded = loadingState === false;
      if (hasLoaded && props.modelId) {
        fetchExistingModel();
      } else if (hasLoaded) {
        $store.dispatch("valuationModel/setNewModel", {
          templates: templates,
          $store: $store,
          isDev: dev.value,
          amountOfYearsForward: 6,
          parseUtcDateTime: parseUtcDateTime,
          utcEpochToShortDate: utcEpochToShortDate.value,
          currentIso: currentIso.value,
          titleOverride: queryTitle,
          userTier: customGroup.value,
          refCode: refCode.value
        });
      }
    }, {
      immediate: true
    });
    return {
      __sfc: true,
      props: props,
      $featureToggle: $featureToggle,
      $store: $store,
      customGroup: customGroup,
      dev: dev,
      refCode: refCode,
      utcEpochToShortDate: utcEpochToShortDate,
      parseUtcDateTime: parseUtcDateTime,
      currentIso: currentIso,
      $route: $route,
      fetchExistingModel: fetchExistingModel,
      tid: tid,
      cid: cid,
      queryTitle: queryTitle,
      initialSaveQueryParam: initialSaveQueryParam,
      showValuationModel: showValuationModel,
      key: key,
      loadingStore: loadingStore,
      loadingVm: loadingVm,
      loading: loading,
      hasStoreError: hasStoreError,
      isOutdated: isOutdated,
      userHasUpgradedOrDowngraded: userHasUpgradedOrDowngraded,
      initialSave: initialSave,
      PeModelLoader: PeModelLoader,
      PeHead: PeHead,
      templates: templates
    };
  }
};