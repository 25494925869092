import "core-js/modules/es.number.constructor.js";
import { onMounted } from "vue";
import useBaseUtils from "~/functions/useBaseUtils";
import StyledDialog from "~/components/StyledDialog.vue";
export default {
  __name: 'CreateModelDialog',
  props: {
    cid: {
      type: Number,
      "default": null
    },
    tid: {
      type: Number,
      "default": null
    },
    type: {
      type: String,
      "default": "simple"
    } // "simple" or "advanced"
  },
  emits: ["close"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var titleRef = ref("");
    var open = ref(true);
    var selectedModelType = ref("pe");
    var titleInput = ref();
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      refCode = _useBaseUtils.refCode;
    var $router = useRouter();
    onMounted(function () {
      titleInput.value.focus();
    });
    var isAdvancedType = computed(function () {
      return props.type === "advanced";
    });
    var onCreate = function onCreate() {
      open.value = false;
      $router.push({
        path: "/stock/model",
        query: {
          cid: props.cid,
          tid: props.tid,
          ref: refCode.value,
          title: titleRef.value,
          initialSave: true
        }
      });
    };
    var handleUpdateVisible = function handleUpdateVisible(value) {
      open.value = value;
      if (value === false) {
        emit("close");
      }
    };
    var modelTypes = [{
      text: "PE Valuation",
      value: "pe"
    }];
    return {
      __sfc: true,
      props: props,
      titleRef: titleRef,
      open: open,
      selectedModelType: selectedModelType,
      emit: emit,
      titleInput: titleInput,
      darkActive: darkActive,
      refCode: refCode,
      $router: $router,
      isAdvancedType: isAdvancedType,
      onCreate: onCreate,
      handleUpdateVisible: handleUpdateVisible,
      modelTypes: modelTypes,
      StyledDialog: StyledDialog
    };
  }
};