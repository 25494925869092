var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"max-width":_vm.remove ? '384px' : '400px',"overlay-color":_setup.darkActive ? 'rgb(38, 38, 38)' : 'rgb(50, 50, 50)',"overlay-opacity":_setup.darkActive ? 0.85 : 0.5},on:{"input":_setup.onClose},model:{value:(_setup.open),callback:function ($$v) {_setup.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"dialog-card rounded-lg",attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex pt-3 pr-3"},[_c('div',{staticClass:"mr-0 ml-auto"},[_c('v-icon',{on:{"click":_setup.onClose}},[_vm._v("mdi-close")])],1)]),_vm._v(" "),_c('v-card-title',{staticClass:"px-8"},[_c('h4',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_setup.I18nFn(_setup.message))+"\n        ")])]),_vm._v(" "),_c('v-card-actions',{staticClass:"pb-8 pt-4 px-8 actions"},[_c('v-btn',{staticClass:"text-capitalize px-6",attrs:{"elevation":"0","color":"gray"},on:{"click":_setup.onClose}},[_vm._v("\n          "+_vm._s(_setup.I18nFn("Cancel"))+"\n        ")]),_vm._v(" "),_c('v-btn',{staticClass:"text-capitalize px-6",class:{
            grey: !_setup.darkActive,
            'darken-4': !_setup.darkActive,
            'white--text': !_setup.darkActive,
            'black--text': _setup.darkActive,
            primaryAction: _setup.darkActive,
          },attrs:{"elevation":"0","text":""},on:{"click":_setup.onProceed}},[_vm._v("\n          "+_vm._s(_setup.I18nFn("Continue"))+"\n          "),(_setup.loader)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"size":18,"width":2,"color":_setup.darkActive ? 'primaryAction' : 'white',"indeterminate":""}}):_vm._e()],1)],1)],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":"success"},model:{value:(_setup.successMsg),callback:function ($$v) {_setup.successMsg=$$v},expression:"successMsg"}},[_vm._v("\n    "+_vm._s(_setup.I18nFn("Model added to hub summary"))+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }