import useBaseUtils from "~/functions/useBaseUtils";
import PeSummaryShareable from "~/components/valuationModels/Pe/PeSummaryShareable.vue";
import { createChartOptions } from "~/utils/valuationTransform/peModel";
import { getLogo, isValidLogo } from "~/utils/tools/logos";
export default {
  __name: 'PeSummary',
  props: {
    realizedAt: {
      type: String,
      "default": ""
    },
    chartData: {
      type: Object,
      "default": function _default() {}
    },
    dateRange: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    dateArray: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    // the ones below are the ones used for PeSummaryShareable
    assumptionCases: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    formatter: {
      type: Function,
      "default": function _default(v) {
        return v;
      }
    },
    forecastTableData: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    forecastTableDateRange: {
      type: Object,
      "default": function _default() {
        return {
          start: null,
          end: null
        };
      }
    },
    historicalYears: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    historicalTableFilteredData: {
      type: Array,
      "default": function _default() {
        return [];
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var cid = computed(function () {
      return $store.state.valuationModel.metadata.companyid;
    });
    var tickerSymbol = computed(function () {
      return $store.state.valuationModel.metadata.tickersymbol;
    });
    var companyName = computed(function () {
      return $store.state.valuationModel.metadata.companyname;
    });
    var logo = computed(function () {
      var _getLogo;
      return (_getLogo = getLogo(cid.value)) === null || _getLogo === void 0 ? void 0 : _getLogo.data;
    });
    var containerStyles = computed(function () {
      return {
        "--table-bg": darkActive.value ? "#29241b" : "#fff9e7",
        "--table-highlight": darkActive.value ? "#4d402e" : "#fdf3cb",
        "--table-border-color": darkActive.value ? "#a27900" : "#fbbc07"
      };
    });
    var chartOpts = computed(function () {
      return createChartOptions({
        chartData: props.chartData,
        darkActive: darkActive.value
      });
    });
    var modelId = computed(function () {
      return $store.state.valuationModel.currentModelId;
    });
    return {
      __sfc: true,
      props: props,
      darkActive: darkActive,
      I18nFn: I18nFn,
      $store: $store,
      cid: cid,
      tickerSymbol: tickerSymbol,
      companyName: companyName,
      logo: logo,
      containerStyles: containerStyles,
      chartOpts: chartOpts,
      modelId: modelId,
      PeSummaryShareable: PeSummaryShareable,
      isValidLogo: isValidLogo
    };
  }
};