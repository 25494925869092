import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import useBaseUtils from "~/functions/useBaseUtils";
import NumericInput from "~/components/inputs/NumericInput.vue";
import UnauthorizedIcon from "~/components/UnauthorizedIcon.vue";
import PeCell from "~/components/valuationModels/Pe/PeCell.vue";
import { findExRate } from "~/utils/ciq";
import { shouldDisplayInput, getRowKey } from "~/utils/constants/valuationModel";
export default {
  __name: 'PeOperatingData',
  props: {
    displayedDateArr: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    displayedRowArr: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    tableData: {
      type: Object,
      "default": function _default() {}
    },
    tableRowLabels: {
      type: Object,
      "default": function _default() {}
    },
    userInputRows: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    currentIsoCurrency: {
      type: String,
      "default": "USD"
    },
    desiredUnit: {
      type: Number,
      "default": 1
    },
    formatter: {
      type: Function,
      "default": function _default(value) {
        return value;
      }
    },
    options: {
      type: Object,
      "default": function _default() {
        return {
          getExRate: findExRate,
          displayInput: shouldDisplayInput,
          getRowKey: getRowKey
        };
      }
    },
    financialDataset: {
      type: Boolean,
      "default": false
    },
    freezeFirstColumn: {
      type: Boolean,
      "default": false
    },
    assumptionCaseIndex: {
      type: Number,
      "default": 0
    },
    previewMode: {
      type: Boolean,
      "default": false
    }
  },
  emits: ["valueChange"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      dev = _useBaseUtils.dev;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var getRealizedAtDateStr = function getRealizedAtDateStr(dateObj) {
      var label = dateObj === null || dateObj === void 0 ? void 0 : dateObj.fiscalDate;
      var suffix = dateObj.isEstimate ? "E" : "A";
      return "".concat(label).concat(suffix);
    };
    var handleValueChange = function handleValueChange(value, calendarYear, id) {
      emit("valueChange", value, calendarYear, id);
    };
    var storeValues = computed(function () {
      return $store.state.valuationModel.data.userInputsDataArray;
    });
    var currentAssumptionCaseValues = computed(function () {
      return storeValues.value[props.assumptionCaseIndex];
    });
    var scaleInputValue = function scaleInputValue(rowObj, dateKeyStr) {
      var _currentAssumptionCas;
      var inputMetaData = props.userInputRows.find(function (item) {
        return item.metricKey === rowObj.key;
      });
      var multiplyBy = (inputMetaData === null || inputMetaData === void 0 ? void 0 : inputMetaData.multiplyBy) || 1;
      var val = (_currentAssumptionCas = currentAssumptionCaseValues.value) === null || _currentAssumptionCas === void 0 || (_currentAssumptionCas = _currentAssumptionCas[rowObj.key]) === null || _currentAssumptionCas === void 0 ? void 0 : _currentAssumptionCas[dateKeyStr];
      if (val === 0 || !val) {
        return 0;
      }
      var scaled = val * multiplyBy;
      return scaled;
    };
    var isFirstHighlightRow = function isFirstHighlightRow(idx, rowArr) {
      var firstHighlightIndex = rowArr.indexOf(rowArr.find(function (item) {
        return item.highlight;
      }));
      return idx === firstHighlightIndex;
    };
    var findLastHighlightIndex = function findLastHighlightIndex(array) {
      var reversedArray = _toConsumableArray(array).reverse();
      var reversedIndex = reversedArray.findIndex(function (obj) {
        return obj.highlight;
      });
      return reversedIndex === -1 ? -1 : array.length - 1 - reversedIndex;
    };
    var isLastHighlightRow = function isLastHighlightRow(idx, rowArr) {
      var lastHighlightIndex = findLastHighlightIndex(rowArr);
      return idx === lastHighlightIndex;
    };
    var generateRowClasses = function generateRowClasses(row, idx, displayedRowArr) {
      return {
        "yellow-highlight": row === null || row === void 0 ? void 0 : row.highlight,
        "blank-row": row.key === "blank",
        compact: row.spaced,
        first: (row === null || row === void 0 ? void 0 : row.highlight) && isFirstHighlightRow(idx, displayedRowArr),
        last: (row === null || row === void 0 ? void 0 : row.highlight) && isLastHighlightRow(idx, displayedRowArr)
      };
    };
    var generateLabelClasses = function generateLabelClasses(row) {
      return {
        // 'font-weight-bold': row.bold,
        "font-italic": row.spaced,
        "text-left": true
      };
    };
    var generateCommonCellClasses = function generateCommonCellClasses(userInputRows, date, row, cellData) {
      return {
        "red--text": !props.options.displayInput(userInputRows, date, row.key) && (cellData === null || cellData === void 0 ? void 0 : cellData.v) < 0,
        // 'font-weight-bold': row.bold,
        "has-input": props.options.displayInput(userInputRows, date, row.key),
        "font-italic": ["pct", "whole_pct"].includes(row.format) && !props.options.displayInput(userInputRows, date, row.key)
      };
    };
    var getAllowNegativeProp = function getAllowNegativeProp(userInputRows, key) {
      var _userInputRows$find;
      return (_userInputRows$find = userInputRows.find(function (i) {
        return i.metricKey === key;
      })) === null || _userInputRows$find === void 0 ? void 0 : _userInputRows$find.allowNegative;
    };
    var log = function log(data) {
      if (dev.value) {
        console.log({
          data: data
        });
      }
    };
    return {
      __sfc: true,
      props: props,
      emit: emit,
      I18nFn: I18nFn,
      dev: dev,
      $store: $store,
      getRealizedAtDateStr: getRealizedAtDateStr,
      handleValueChange: handleValueChange,
      storeValues: storeValues,
      currentAssumptionCaseValues: currentAssumptionCaseValues,
      scaleInputValue: scaleInputValue,
      isFirstHighlightRow: isFirstHighlightRow,
      findLastHighlightIndex: findLastHighlightIndex,
      isLastHighlightRow: isLastHighlightRow,
      generateRowClasses: generateRowClasses,
      generateLabelClasses: generateLabelClasses,
      generateCommonCellClasses: generateCommonCellClasses,
      getAllowNegativeProp: getAllowNegativeProp,
      log: log,
      NumericInput: NumericInput,
      UnauthorizedIcon: UnauthorizedIcon,
      PeCell: PeCell,
      getRowKey: getRowKey
    };
  }
};