import "core-js/modules/es.array.find.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
export default {
  __name: 'StyledMenu',
  props: {
    open: {
      type: [Boolean],
      "default": false
    },
    right: {
      type: Boolean,
      "default": false
    },
    activatorProps: {
      type: Object,
      "default": function _default() {
        return {
          label: "",
          icon: "mdi-dots-horizontal",
          rotateIconOnOpen: false
        };
      }
    },
    items: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    initialValue: {
      type: [String, Number],
      "default": ""
    },
    dropdownMinWidth: {
      type: String,
      "default": "300px"
    },
    zIndex: {
      type: Number,
      "default": 30
    },
    disabled: {
      type: Boolean,
      "default": false
    },
    displayActiveMenuLabel: {
      type: Boolean,
      "default": true
    },
    displayActiveMenuIcon: {
      type: Boolean,
      "default": true
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var wrapper = ref(null);
    var toggle = ref(props.open);
    var selected = ref(props.initialValue);
    var activeMenuItem = computed(function () {
      if (!props.items.length) {
        return false;
      }
      return props.items.find(function (i) {
        return i.value === selected.value;
      });
    });
    var changeCurrentItem = function changeCurrentItem(item) {
      if (item.lock) {
        return;
      }
      var value = item.value;
      selected.value = value;
      emit("update:value", value);
    };
    return {
      __sfc: true,
      props: props,
      emit: emit,
      wrapper: wrapper,
      toggle: toggle,
      selected: selected,
      activeMenuItem: activeMenuItem,
      changeCurrentItem: changeCurrentItem
    };
  }
};