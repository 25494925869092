var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('table',{staticClass:"operating-table",class:{
    frozen: _vm.freezeFirstColumn,
  },attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"row-label text-left"},[_c('div',[_vm._v("\n          "+_vm._s(_setup.I18nFn("Operating Data"))+"\n        ")])]),_vm._v(" "),_vm._l((_vm.displayedDateArr),function(date){return _c('th',{key:`operatingData_${date.calendarYear}`,staticClass:"text-right"},[_vm._v("\n        "+_vm._s(_setup.getRealizedAtDateStr(date))+"\n      ")])})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.displayedRowArr),function(row,idx){return _c('tr',{key:_vm.options.getRowKey(row),class:_setup.generateRowClasses(row, idx, _vm.displayedRowArr)},[(row.key === 'blank')?_vm._l((_vm.displayedDateArr.length + 1),function(number){return _c('td',{key:number},[_vm._v("\n           \n        ")])}):[_c('td',{staticClass:"row-label",class:_setup.generateLabelClasses(row)},[_c('div',[(row.spaced)?[_vm._v("   ")]:_vm._e(),_vm._v(" "),(_vm.tableRowLabels?.[row.key])?[_vm._v("\n              "+_vm._s(_setup.I18nFn(_vm.tableRowLabels[row.key]))+"\n            ")]:_vm._e()],2)]),_vm._v(" "),_vm._l((_vm.displayedDateArr),function(date){return _c('td',{key:date?.dateKey,staticClass:"text-right",class:_setup.generateCommonCellClasses(
              _vm.userInputRows,
              date,
              row,
              _vm.tableData?.[row.key]?.[date.dateKey]
            )},[(_vm.tableData?.[row.key]?.[date.dateKey]?.unauth)?_c(_setup.UnauthorizedIcon):(_vm.options.displayInput(_vm.userInputRows, date, row.key))?[_c(_setup.NumericInput,{key:`${row.key}_${date.dateKey}`,attrs:{"initial-value":_setup.scaleInputValue(row, date.dateKey),"calendar-year":date.calendarYear,"suffix":row.inputSuffix,"allow-negative-numbers":_setup.getAllowNegativeProp(_vm.userInputRows, row.key),"disabled":_vm.previewMode},on:{"valueChange":(v, c) => _setup.handleValueChange(v, c, row.key)}})]:(isFinite(_vm.tableData?.[row.key]?.[date.dateKey]?.v))?_c('span',{on:{"click":function($event){return _setup.log(_vm.tableData?.[row.key]?.[date.dateKey])}}},[_c(_setup.PeCell,{attrs:{"value":_vm.tableData?.[row.key]?.[date.dateKey]?.v,"unit-id":_vm.tableData?.[row.key]?.[date.dateKey]?.u,"ex-rate":_vm.options.getExRate(_vm.tableData?.[row.key]?.[date.dateKey]),"cell-currency":_vm.tableData?.[row.key]?.[date.dateKey]?.iso,"hide":_vm.tableData?.[row.key]?.[date.dateKey]?.hide,"format":row.format,"desired-unit":_vm.desiredUnit,"formatter":_vm.formatter,"currency":_vm.currentIsoCurrency}})],1):_vm._e()],2)})]],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }