import "core-js/modules/es.string.ends-with.js";
import useBaseUtils from "~/functions/useBaseUtils";
import StyledDialog from "~/components/StyledDialog.vue";
import { tierRestrictions } from "~/utils/valuationTransform/models";
export default {
  __name: 'TierWarningDialog',
  emits: ["close"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var _useBaseUtils = useBaseUtils(),
      customGroup = _useBaseUtils.customGroup,
      darkActive = _useBaseUtils.darkActive,
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var open = computed(function () {
      return $store.state.valuationModel.dialogs.tierWarning;
    });
    var handleChange = function handleChange(state) {
      $store.dispatch("valuationModel/changeDialogState", {
        dialogId: "tierWarning",
        value: state
      });
      if (state === false) {
        emit("close");
      }
    };
    var currentTier = computed(function () {
      if (customGroup.value.endsWith("-free")) {
        return "free";
      }
      if (customGroup.value.endsWith("-plus")) {
        return "plus";
      }
      return "pro";
    });
    var modelLimit = computed(function () {
      if (customGroup.value.endsWith("-free")) {
        return tierRestrictions.free.models;
      }
      if (customGroup.value.endsWith("-plus")) {
        return tierRestrictions.plus.models;
      }
      return tierRestrictions.pro.models;
    });
    var tierToUpgradeTo = computed(function () {
      if (customGroup.value.endsWith("-free")) {
        return "plus";
      }
      return "pro";
    });
    var upgradeMsg = computed(function () {
      if (customGroup.value.endsWith("-free")) {
        return "Upgrade to TIKR Plus for only $14.95 a month to create up to ".concat(tierRestrictions.plus.models, " models with no lock signs. You also get 10 years of global financial and charting history, 3 years of transcripts, 3 years of analyst estimates and more!");
      }
      return "Upgrade to TIKR Pro for only $29.95 a month to create up to ".concat(tierRestrictions.pro.models, " models. You also get 20 years of global financial and charting history, full global transcripts, 5 years of analyst estimates and more!");
    });
    return {
      __sfc: true,
      emit: emit,
      customGroup: customGroup,
      darkActive: darkActive,
      refCode: refCode,
      I18nFn: I18nFn,
      $store: $store,
      open: open,
      handleChange: handleChange,
      currentTier: currentTier,
      modelLimit: modelLimit,
      tierToUpgradeTo: tierToUpgradeTo,
      upgradeMsg: upgradeMsg,
      StyledDialog: StyledDialog
    };
  }
};